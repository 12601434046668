const messages = {
  homevall:{
    homevall1: "초대",
    homevall2: "연결됨",
    homevall3: "연결",
    homevall4: "홈",
    homevall5: "시장",
    homevall6: "채굴",
    homevall7: "초대",
    homevall8: "연결 성공",
    homevall9: "연결 실패",
    homevall10: "바인딩 확인",
    homevall11: "취소",
    homevall12: "확인",
    homevall12_1: "담보 대출",
    homevall12_2: "펫 월드",
    homevall12_3: "스포츠 경쟁",
    homevall12_4: "확인",
    homevall12_5: "게임 엔터테인먼트",
    swap: "스왑",
    referInputPlaceholder: "추천인 주소 입력",
    invalidAddress: "유효하지 않은 주소",
    cannotReferSelf: "자신을 추천인으로 설정할 수 없습니다",
    referNeedsRefer: "이 추천인은 자신의 추천인이 있어야 합니다",
    bindSuccess: "바인딩 성공",
    bindFailed: "바인딩 실패",
    bindReferError: "추천인 바인딩 오류",
    checkReferralError: "추천 상태 확인 실패"
  },
  home: {
    home1: "채굴 연이율",
    home2: "예금",
    home3: "교환 금액 입력",
    home4: "계좌 잔액",
    home5: "교환 금액",
    home6: "즉시 교환",
    home7: "채굴 해시파워",
    home8: "채굴 연이율",
    home9: "활성화",
    home10: "광산",
    home11: "DBTC 채굴 총 생산량",
    home12: "순수익률",
    home13: "이미 생산됨",
    home14: "남은 것",
    home15: "총 한도",
    home16: "총 해시파워",
    home17: "채굴 토큰",
    home18: "단일 화폐",
    home19: "모든 화폐",
    home20: "LP 화폐",
    home21: "토큰 선택",
    home22: "화폐",
    home23: "채굴 수익",
    home32: "닌자 보고서 보기",
    home323: "서틱 보고서 보기",
    home33: "데이터",
    home34: "미개방",
    home35: "상세 정보",
    home36: "즉시 소각 비율",
    home37: "유동성 증가 비율",
    home38: "프로젝트 팀 비율",
    home39: "DBTC 구매 소각 비율",
    home40: "승인 성공",
    home41: "승인 실패",
    home42: "입력해 주세요",
    home43: "입금 성공",
    home44: "입금 실패",
    home45: "지갑 연결해주세요",
    home46: "활성화되지 않음",
    home47:"입금된 토큰의 가치는 110 USDT 이상이어야 합니다.",
    home48:"입금된 토큰 수량이 지갑 잔액보다 많습니다!",
    copySuccess:"복사 성공"
  },
  bazaar: {
    bazaar1: "총 기부 금액",
    bazaar2: "모든 시장",
    bazaar3: "화폐",
    bazaar4: "총 예금",
    bazaar5: "공약율",
    bazaar6: "유동성",
    bazaar7: "예금 금리",
    bazaar8: "담보 인자",
    bazaar9: "예금 순위",
    bazaar10: "보안 보고서 보기",
    bazaar11: "유명 감사 회사 Certik/CFG.ninja에 의해 보안 감사 완료"
  }
,
  ore:{
    ore1: "전체 네트워크 해시레이트",
    ore2: "전체 네트워크 남은 DBTC 총 생산량",
    ore3: "전체 네트워크 일일 해시파워",
    ore4: "전체 네트워크 일일 DBTC 총 생산",
    ore5: "현재 일일 생산",
    ore6: "내 총 해시파워",
    ore7: "예금 해시파워",
    ore8: "대기 중",
    ore9: "받기",
    ore10: "받음",
    ore11: "개인 해시파워 순위",
    ore12: "전설의 명단",
    ore13: "전체 네트워크 누적 보너스",
    ore14: "받을 보너스",
    ore15: "상위 10명 수수료 보너스 기회",
    ore16: "순위",
    ore17: "주소",
    ore18: "해시파워",
    ore19: "비율",
    ore20: "친구 초대하여 구매 및 NFT 획득",
    ore21: "팀 예금",
    ore22: "수령 기록",
    ore23: "내 추천 해시파워",
    ore24: "해시파워 생성 후 다음 날부터 30일 동안 생산됩니다",
    ore25: "오늘의 해시파워 팩터",
    ore26: "받을 수 있는 것이 없습니다",
    ore27: "성공적으로 받음",
    ore28: "프라이빗 세일 해시파워",
    ore29: '오늘 시가',
    ore30: '상승률',
    ore31: '현재 가격',

  },
  my:{
    my1: "친구 초대",
    my2: "높은 해시파워 획득",
    my3: "이미 획득한 해시파워",
    my4: "귀하의 초대한 사람",
    my5: "초대 링크 복사",
    my6: "귀하가 초대한 사람",
    my7: "펼치기",
    my8: "접기",
    my9: "순위",
    my10: "주소",
    my11: "비율",
    my12: "사람",
    my13: "해시파워",
    my14: "비활성화된 해시파워",
    my15: "활성화된 추천 해시파워"

  },
  record:{
    record1: "수령 기록",
    record2: "당일 해시파워 생산",
    record3: "오늘의 예금 해시파워"
  },swap: {
    sell: "판매",
    buy: "구매",
    balance: "잔액",
    price: "가격",
    slippage: "슬리피지",
    exchange: "교환",
    approve: "승인",
    bestPrice: "최적의 가격",
    enterAmount: "DBTC 수량 입력",
    insufficientApproval: "승인된 가 부족합니다",
    exchangeSuccess: "교환 성공",
    exchangeFail: "교환 실패",
    approveSuccess: "승인 성공",
    approveFail: "승인 실패",
    bestPriceError: "가격 가져오기 실패",
    fixedRate: "",
    notEnoughAllowance: "승인 한도 부족",
    invalidAmount: "유효한 금액을 입력하세요"
  }
}

export default messages
